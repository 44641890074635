<script lang="ts">
  import Panel from "../lib/__panel.svelte";

  import type { ClientBackgroundUsers } from "./../serverUtility";
  import { faker } from "@faker-js/faker";
  import {
    TabCommandsList,
    TabCommandData,
    ComputerCommandData,
    DefaultSettings,
    DefaultState,
  } from "./../utility";

  let computerList: ClientBackgroundUsers = {
    TestComputer: { settings: new DefaultSettings(), tabList: {} },
  };

  function genComputer() {
    const computerName = faker.name.firstName();
    computerList[computerName] = {
      settings: new DefaultSettings(),
      tabList: {},
    };
    return computerName;
  }

  genData(false);
  function genData(manual: boolean = false) {
    console.log("Generating data...");
    Object.entries(computerList).forEach(([computerName, computer]) => {
      Object.entries(computer.tabList)
        .filter(([tabID, tabData]) => tabData.currentState.inCall)
        .forEach(([tabID, tabData]) => {
          if (
            Math.random() >
            1 - Object.keys(computerList[computerName].tabList[tabID].users).length / 40
          ) {
            console.log("Removing user from " + computerName + ", " + tabID + "...");
            // Remove a random user
            delete computerList[computerName].tabList[tabID].users[
              Object.keys(tabData.users)[
                Math.floor(Math.random() * Object.keys(tabData.users).length)
              ]
            ];
            computerList[computerName].tabList[tabID].users =
              computerList[computerName].tabList[tabID].users;
          }
          if (
            Math.random() >
            Math.log(
              Object.keys(computerList[computerName].tabList[tabID].users).length + 1
            )
          ) {
            console.log("Adding user to " + computerName + ", " + tabID + "...");
            computerList[computerName].tabList[tabID].users[faker.name.findName()] = {
              muted: false,
              talking: false,
              cameraEnabled: true,
              isPresentation: false,
              exclude: false,
            };
          }
          Object.entries(tabData.users).forEach(([userName, user]) => {
            if (Math.random() > 0.9) {
              computerList[computerName].tabList[tabID].users[userName].muted =
                !user.muted;
            }
            if (Math.random() > 0.9) {
              computerList[computerName].tabList[tabID].users[userName].cameraEnabled =
                !user.cameraEnabled;
            }
            if (
              !computerList[computerName].tabList[tabID].users[userName].muted &&
              Math.random() > 0.3
            ) {
              computerList[computerName].tabList[tabID].users[userName].talking =
                !user.talking;
            }
          });
        });
    });
    if (!manual) {
      setTimeout(genData, Math.floor(Math.random() * 5000) + 500);
    }
  }

  function openNewMeet(
    computerName: string,
    meetCode: string,
    fullscreen: boolean = false,
    regularWindow: boolean = false
  ) {
    if (meetCode.includes("?")) {
      meetCode = meetCode.substring(0, meetCode.indexOf("?"));
    }
    commandGen(computerName, "commandComputer", {
      command: "openMeet",
      args: { newMeet: { newURL: meetCode, fullscreen, regularWindow } },
    });
  }

  function updateSettings(computer: string) {
    let newSettings = {};
    document
      .querySelectorAll(`input[data-computer="${computer}"][data-settings-name]`)
      .forEach((element: HTMLInputElement) => {
        let setting = element.getAttribute("data-settings-name");
        if (typeof setting === "string") {
          if (element.getAttribute("type") === "checkbox") {
            if (computerList[computer].settings[setting] !== element.checked) {
              newSettings[setting] = element.checked;
            }
          } else if (setting === "hide_elements_list") {
            let list = element.value.split(",");
            if (
              computerList[computer].settings[setting].sort().toString() !==
              list.sort().toString()
            ) {
              newSettings[setting] = list;
            }
          } else if (computerList[computer].settings[setting] !== element.value) {
            newSettings[setting] = element.value;
          }
        }
      });
    commandGen(computer, "commandComputer", {
      command: "updateSettings",
      args: { settings: newSettings },
    });
  }

  function commandGen(
    computer: string,
    action: "commandTab" | "commandComputer",
    data?: TabCommandData | ComputerCommandData
  ) {
    let randomDelay = Math.floor(Math.random() * 150) + 50;
    if (action === "commandTab") {
      let tabData = data as TabCommandData;
      setTimeout(() => {
        if (tabData.command === "toggleFullScreen") {
          computerList[computer].tabList[tabData.tabID].currentState.fullscreen =
            !computerList[computer].tabList[tabData.tabID].currentState.fullscreen;
        } else if (tabData.command === "toggleVideoOnlyGrid") {
          computerList[computer].tabList[tabData.tabID].currentState.videoOnlyGrid =
            !computerList[computer].tabList[tabData.tabID].currentState.videoOnlyGrid;
        } else if (tabData.command === "toggleGridView") {
          computerList[computer].tabList[tabData.tabID].currentState.grid =
            !computerList[computer].tabList[tabData.tabID].currentState.grid;
        } else if (tabData.command === "toggleElements") {
          computerList[computer].tabList[tabData.tabID].currentState.elements =
            !computerList[computer].tabList[tabData.tabID].currentState.elements;
        } else if (tabData.command === "toggleMicrophone") {
          computerList[computer].tabList[tabData.tabID].currentState.micEnabled =
            !computerList[computer].tabList[tabData.tabID].currentState.micEnabled;
        } else if (tabData.command === "toggleCamera") {
          computerList[computer].tabList[tabData.tabID].currentState.cameraEnabled =
            !computerList[computer].tabList[tabData.tabID].currentState.cameraEnabled;
        } else if (tabData.command === "toggleCurtain") {
          computerList[computer].tabList[tabData.tabID].currentState.curtain =
            !computerList[computer].tabList[tabData.tabID].currentState.curtain;
        } else if (tabData.command === "toggleExcludeUser") {
          //TODO: Add logic to toggle exclude user
        } else if (tabData.command === "fullVideoUser") {
          computerList[computer].tabList[tabData.tabID].currentState.pinned = (
            data.args as { user: string; exclude: boolean }
          ).user;
        } else if (tabData.command === "close") {
          delete computerList[computer].tabList[tabData.tabID];
          computerList[computer].tabList = computerList[computer].tabList;
        } else if (tabData.command === "refresh") {
          let copy = computerList[computer].tabList[tabData.tabID];
          delete computerList[computer].tabList[tabData.tabID];
          computerList[computer].tabList = computerList[computer].tabList;
          setTimeout(() => {
            computerList[computer].tabList[tabData.tabID] = copy;
          }, 1000);
        } else if (tabData.command === "clickJoinMeet") {
          computerList[computer].tabList[tabData.tabID].currentState.inCall = true;
        }
      }, randomDelay);
    } else if (action === "commandComputer") {
      let computerData = data as ComputerCommandData;
      setTimeout(() => {
        if (computerData.command === "updateSettings") {
          Object.entries(computerData.args.settings).forEach(([setting, value]) => {
            if (
              computerList[computer].settings[setting] != null &&
              computerList[computer].settings[setting] !== value
            ) {
              computerList[computer].settings[setting] = value;
            }
          });
        } else if (computerData.command === "openMeet") {
          let random = Math.floor(Math.random() * 1000);
          computerList[computer].tabList[random] = {
            currentState: new DefaultState(),
            users: {},
          };
          computerList[computer].tabList[random].currentState.meetURL =
            computerData.args.newMeet.newURL;
          computerList[computer].tabList[random].currentState.fullscreen =
            computerData.args.newMeet.fullscreen;
        } else if (computerData.command === "commandTab") {
          commandGen(computer, "commandTab", computerData.args.tabCommand);
        }
      }, randomDelay);
    }
  }

  function commandTab(
    action: TabCommandsList,
    computerName: string,
    tabID: string,
    args: any = null
  ) {
    commandGen(computerName, "commandTab", {
      tabID: tabID,
      command: action,
      args,
    });
  }

  function closeTab(computerName: string, tabID: string) {
    if (confirm("Are you sure you want to close this tab?")) {
      commandTab("close", computerName, tabID);
    }
  }

  function inputChange(event) {
    const action = event.currentTarget.getAttribute("data-action") as string;
    const computer = event.currentTarget.getAttribute("data-computer") as string;
    const tabID = event.currentTarget.getAttribute("data-tab-id") as string;
    let args;
    if (computer && tabID && action) {
      switch (action) {
        case "excludeUser":
          args = {
            user: event.currentTarget.getAttribute("data-user"),
            exclude: event.currentTarget.checked,
          };
          break;
        case "fullVideoUser":
          if (event.currentTarget.checked) {
            args = event.currentTarget.getAttribute("data-user");
          } else {
            commandGen(computer, "commandTab", {
              tabID,
              command: "regularVideo",
            });
            return;
          }
          break;
        default:
          args = event.currentTarget.checked ?? false;
      }
      commandTab(<TabCommandsList>action, computer, tabID, args);
    }
  }

  function inputKeyup(
    event: KeyboardEvent & {
      currentTarget: EventTarget & HTMLInputElement;
    },
    computerName: string
  ) {
    if (event.key === "Enter") {
      let newURL: string = event.currentTarget.value;
      let fullscreen = (<HTMLInputElement>(
        document.getElementById(`toggleFullScreenURL${computerName}`)
      )).checked;
      let regularWindow = (<HTMLInputElement>(
        document.getElementById(`togglePanel${computerName}`)
      )).checked;
      event.currentTarget.value = "";
      commandGen(computerName, "commandComputer", {
        command: "openMeet",
        args: { newMeet: { newURL: newURL, fullscreen, regularWindow } },
      });
    }
  }
</script>

<div class="alert alert-primary">
  This page is a demonstration of a panel. Open a meet and click join meet and data users
  will randomly generate. Not all features work in test mode.<br />
  <button on:click={() => genData(true)} class="btn btn-primary">
    Click to Generate Random Data Manually
  </button>
  <button on:click={genComputer} class="btn btn-primary">
    Click to Add a Computer
  </button>
</div>

<Panel
  {computerList}
  {openNewMeet}
  {inputChange}
  {commandTab}
  {closeTab}
  {updateSettings}
  {inputKeyup}
/>
