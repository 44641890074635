<script lang="ts">
  import { read, utils, writeFile } from "xlsx";
  import type { ClientBackgroundUsers } from "./../serverUtility";
  export let computerList: ClientBackgroundUsers = {};
  export let openNewMeet: (
    computer: string,
    meetCode: string,
    fullscreen?: boolean,
    regularWindow?: boolean
  ) => void;
  export let inputChange: (event: Event) => void;
  export let commandTab: (
    tabCommand: string,
    backgroundName: string,
    tabNumber: string
  ) => void;
  export let closeTab: (computerName: string, tabID: string) => void;
  export let updateSettings: (computer: string) => void;
  export let inputKeyup: (
    event: KeyboardEvent & {
      currentTarget: EventTarget & HTMLInputElement;
    },
    backgroundName: string
  ) => void;
  export let customAlert: string = "";

  type OpenMeetArray = Array<{
    computerName: string;
    meetCode: string;
    fullscreen?: boolean;
    regularWindow?: boolean;
    invalidMeetCode?: boolean;
    invalidComputerName?: boolean;
  }>;

  let files: FileList;
  let openMeetList: OpenMeetArray = [];
  let massMeetOpenInput: HTMLInputElement;
  let dragArea: HTMLDivElement;
  let outputTypes: { csv: boolean; xlsx: boolean; rtf: boolean; txt: boolean } = {
    csv: false,
    xlsx: false,
    rtf: false,
    txt: false,
  };

  $: if (files && files.length > 0) {
    readFile(files[0]);
  }

  function handleDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
    readFile(e.dataTransfer.files[0]);
    dragArea.classList.remove(
      "bg-success",
      "border",
      "border-3",
      "border-primary",
      "rounded-2",
      "opacity-75"
    );
    dragArea.classList.add("d-none");

    dragArea.innerText = "";
  }

  function handleDrag(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer.files[0]) {
      readFile(e.dataTransfer.files[0]);
    }
    dragArea.classList.add(
      "bg-success",
      "border",
      "border-3",
      "border-primary",
      "rounded-2",
      "opacity-75"
    );
    dragArea.classList.remove("d-none");
    dragArea.innerText = "Drop File Here";
  }

  function handleDragLeave(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer.files[0]) {
      readFile(e.dataTransfer.files[0]);
    }
    dragArea.classList.remove(
      "bg-success",
      "border",
      "border-3",
      "border-primary",
      "rounded-2",
      "opacity-75"
    );
    dragArea.classList.add("d-none");

    dragArea.innerText = "";
  }

  function readFile(f: Blob) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      var workbook = read(data);
      let jsonSheet = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
        header: 1,
      }) as Array<Array<string>>;
      let headers = jsonSheet[0] as Array<string>;
      let computerNameHeader = headers.findIndex((header) =>
        header.match(/computer|name/i)
      );
      if (computerNameHeader === -1) {
        alert("Missing Computer header. Expecting: Computer | Name");
        return;
      } else {
        headers[computerNameHeader] = "computerName";
      }
      let meetCodeHeader = headers.findIndex((header) => header.match(/meet|code|url/i));
      if (meetCodeHeader === -1) {
        alert("Missing Meet Code header. Expecting: Meet | Code | URL");
        return;
      } else {
        headers[meetCodeHeader] = "meetCode";
      }
      let fullscreenHeader = headers.findIndex((header) => header.match(/fullscreen/i));
      if (fullscreenHeader !== -1) {
        headers[fullscreenHeader] = "fullscreen";
      }
      let regularWindowHeader = headers.findIndex((header) =>
        header.match(/regular.*window/i)
      );
      if (regularWindowHeader !== -1) {
        headers[regularWindowHeader] = "regularWindow";
      }
      jsonSheet.shift();
      openMeetList = jsonSheet.reduce(
        (previousValue, currentValue) =>
          previousValue.concat([
            {
              computerName: currentValue[computerNameHeader],
              meetCode: currentValue[meetCodeHeader],
              fullscreen:
                fullscreenHeader > -1
                  ? ["true", "1"].includes(
                      currentValue[fullscreenHeader]?.toString().toLowerCase()
                    )
                  : true,
              regularWindow:
                regularWindowHeader > -1
                  ? ["true", "1"].includes(
                      currentValue[regularWindowHeader]?.toString().toLowerCase()
                    )
                  : false,
              invalidMeetCode: !validMeetCode(currentValue[meetCodeHeader]),
              invalidComputerName: !computerList[currentValue[computerNameHeader]],
            },
          ]),
        [] as OpenMeetArray
      );
    };
    reader.readAsArrayBuffer(f);
  }

  function genEmptyFiles() {
    let newBook = utils.book_new();
    utils.book_append_sheet(
      newBook,
      utils.aoa_to_sheet([
        ["Computer", "Meet", "Fullscreen", "Regular Window"],
        ...Object.keys(computerList).map((computerName) => [computerName, "", "", ""]),
      ]),
      "MassMeetOpenExample"
    );
    Object.entries(outputTypes)
      .filter(([key, value]) => value)
      .forEach(([key, value]) => {
        writeFile(newBook, "MassMeetOpenTemplate." + key, {
          bookType: key as "xlsx" | "csv" | "rtf" | "txt",
        });
      });
  }

  function genExampleFiles() {
    let newBook = utils.book_new();
    utils.book_append_sheet(
      newBook,
      utils.aoa_to_sheet([
        [
          "Computer",
          "Meet",
          "Fullscreen (optional)",
          "Regular Window (optional)",
          "Extra Columns",
        ],
        ["TestComputer", "awc-wtve-afv", "true", "true", "Extra columns are ignored"],
        [
          "TestComputer",
          "InvalidMeetCodeTest",
          "0",
          "1",
          "Only the Computer and Meet columns are required",
        ],
        [
          "InvalidComputerTest",
          "awc-wtve-afv",
          "Anything but 1 or true is false",
          "false",
          "Header Order does not matter",
        ],
      ]),
      "MassMeetOpenExample"
    );
    Object.entries(outputTypes)
      .filter(([key, value]) => value)
      .forEach(([key, value]) => {
        writeFile(newBook, "MassMeetOpenExample." + key, {
          bookType: key as "xlsx" | "csv" | "rtf" | "txt",
        });
      });
  }

  $: if (openMeetList.length > 0) {
    openMeetList = openMeetList.map((row) => {
      let meetCode = validMeetCode(row.meetCode);
      return {
        computerName: row.computerName,
        meetCode: meetCode ? meetCode : row.meetCode,
        fullscreen: row.fullscreen,
        regularWindow: row.regularWindow,
        invalidMeetCode: meetCode === false,
        invalidComputerName: !computerList[row.computerName],
      };
    });
  }

  function validMeetCode(meetCode: string): string | false {
    if (!meetCode.match(/^[a-z]{3}-?[a-z]{4}-?[a-z]{3}(\?(\S+))?$/i)) {
      let meetCodeRegexSearch = meetCode.match(
        /\/[a-z]{3}-?[a-z]{4}-?[a-z]{3}(\?(\S+))?$/i
      );
      if (meetCodeRegexSearch !== null) {
        meetCode = meetCodeRegexSearch[0].substring(1);
      } else {
        return false;
      }
    }
    if (!meetCode.includes("-")) {
      meetCode =
        meetCode.substring(0, 3) +
        "-" +
        meetCode.substring(3, 7) +
        "-" +
        meetCode.substring(7);
    }
    if (meetCode) {
      return meetCode;
    } else {
      return false;
    }
  }

  function openNewMeetHelper(
    computerName: string,
    meetCode?: string,
    fullscreen?: boolean,
    regularWindow?: boolean
  ) {
    let newURL =
      meetCode ?? (<HTMLInputElement>document.getElementById(computerName)).value;
    if (validMeetCode(newURL)) {
      fullscreen =
        fullscreen ??
        (<HTMLInputElement>document.getElementById(`toggleFullScreenURL${computerName}`))
          .checked;
      regularWindow =
        regularWindow ??
        (<HTMLInputElement>document.getElementById(`togglePanel${computerName}`)).checked;
      (<HTMLInputElement>document.getElementById(computerName)).value = "";
      openNewMeet(computerName, newURL, fullscreen, regularWindow);
    } else {
      alert("Please enter a valid meet code");
    }
  }
</script>

<main>
  {#if customAlert}
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      {customAlert}
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      />
    </div>
  {/if}
  <div class="container-fluid g-0 p-3">
    {#if !computerList || Object.keys(computerList).length === 0}
      <div class="alert alert-warning show" role="alert">No connected computers</div>
    {:else}
      <div class="row row-cols-1 row-cols-sm-1 row-cols-lg-2">
        {#each Object.entries(computerList) as [backgroundName, backgroundData]}
          <div class="col" data-computer={backgroundName}>
            <div>
              <h3 class="text-center">
                <span data-computer={backgroundName}>{backgroundName}</span>
              </h3>
              <div class="input-group text-center w-3/4 p-1">
                <input
                  class="form-control"
                  on:keyup={(event) => inputKeyup(event, backgroundName)}
                  type="text"
                  id={backgroundName}
                  placeholder="New Meet Code"
                />
                <input
                  class="btn-check"
                  id="toggleFullScreenURL{backgroundName}"
                  data-computer={backgroundName}
                  type="checkbox"
                /><label
                  class="btn btn-outline-primary"
                  for="toggleFullScreenURL{backgroundName}">Open fullscreen</label
                >
                <input
                  class="btn-check"
                  id="togglePanel{backgroundName}"
                  data-computer={backgroundName}
                  type="checkbox"
                /><label class="btn btn-outline-primary" for="togglePanel{backgroundName}"
                  >Regular Browser Window</label
                >
                <button
                  on:click={() => openNewMeetHelper(backgroundName)}
                  class="btn btn-sm btn-warning submit"
                  data-computer={backgroundName}>Open New Meet</button
                >
              </div>
              {#if backgroundData.tabList}
                {#each Object.entries(backgroundData.tabList) as [tabNumber, tabInfo]}
                  <div class="accordion" id="{backgroundName}{tabNumber}accordion">
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="{backgroundName}{tabNumber}heading"
                      >
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#{backgroundName}{tabNumber}collapse"
                          aria-expanded="false"
                          aria-controls="{backgroundName}{tabNumber}collapse"
                          >{tabInfo.currentState.meetURL || "N/A"}
                          {#if tabInfo.currentState.inCall}
                            <span class="badge bg-success"
                              >{tabInfo.users ? Object.keys(tabInfo.users).length : 0} Users</span
                            >
                          {:else}
                            <span class="badge bg-warning">Not in call</span>
                          {/if}
                        </button>
                      </h2>
                      <div
                        class="accordion-collapse collapse"
                        id="{backgroundName}{tabNumber}collapse"
                        aria-labelledby="{backgroundName}{tabNumber}heading"
                        data-bs-parent="#{backgroundName}{tabNumber}accordion"
                        style=""
                      >
                        <div class="accordion-body" id="{backgroundName}{tabNumber}body">
                          <div class="d-flex justify-content-end">
                            <div class="btn-toolbar btn-toolbar-sm" role="toolbar">
                              <div class="btn-group me-2" role="group">
                                <input
                                  on:change={inputChange}
                                  class="btn-check"
                                  data-action="toggleFullScreen"
                                  id="toggleFullScreen{backgroundName}{tabNumber}"
                                  data-computer={backgroundName}
                                  data-tab-id={tabNumber}
                                  type="checkbox"
                                  checked={tabInfo.currentState.fullscreen}
                                /><label
                                  class="btn btn-outline-primary btn-sm"
                                  for="toggleFullScreen{backgroundName}{tabNumber}"
                                >
                                  {#if tabInfo.currentState.fullscreen}
                                    Fullscreen
                                  {:else}
                                    Windowed
                                  {/if}
                                </label>
                                {#if tabInfo.currentState.pointerLock}
                                  <div class="btn-primary btn-sm">Mouse Captured</div>
                                {:else}
                                  <div class="btn-secondary btn-sm">Mouse Free</div>
                                {/if}
                              </div>
                              <div class="btn-group me-2" role="group">
                                <input
                                  on:change={inputChange}
                                  class="btn-check"
                                  data-action="toggleElements"
                                  id="toggleElements{backgroundName}{tabNumber}"
                                  data-computer={backgroundName}
                                  data-tab-id={tabNumber}
                                  type="checkbox"
                                  disabled={!tabInfo.currentState.inCall}
                                  checked={!tabInfo.currentState.elements}
                                /><label
                                  class="btn btn-outline-primary btn-sm"
                                  for="toggleElements{backgroundName}{tabNumber}"
                                >
                                  {#if !tabInfo.currentState.elements}
                                    Elements Hidden
                                  {:else}
                                    Elements Shown
                                  {/if}
                                </label>
                                <input
                                  on:change={inputChange}
                                  class="btn-check"
                                  data-action="toggleCurtain"
                                  id="toggleCurtain{backgroundName}{tabNumber}"
                                  data-computer={backgroundName}
                                  data-tab-id={tabNumber}
                                  type="checkbox"
                                  disabled={!tabInfo.currentState.inCall}
                                  checked={tabInfo.currentState.curtain}
                                /><label
                                  class="btn btn-outline-primary btn-sm"
                                  for="toggleCurtain{backgroundName}{tabNumber}"
                                >
                                  {#if !tabInfo.currentState.curtain}
                                    Curtain off
                                  {:else}
                                    Curtain on
                                  {/if}
                                </label>
                              </div>
                              <div class="btn-group me-2" role="group">
                                <input
                                  on:change={inputChange}
                                  class="btn-check"
                                  data-action="toggleGridView"
                                  id="toggleGridView{backgroundName}{tabNumber}"
                                  data-computer={backgroundName}
                                  data-tab-id={tabNumber}
                                  type="checkbox"
                                  disabled={!tabInfo.currentState.inCall}
                                  checked={tabInfo.currentState.grid}
                                /><label
                                  class="btn btn-outline-primary btn-sm"
                                  for="toggleGridView{backgroundName}{tabNumber}"
                                >
                                  {#if tabInfo.currentState.grid}
                                    Grid View
                                  {:else}
                                    Standard View
                                  {/if}
                                </label>
                                <input
                                  on:change={inputChange}
                                  class="btn-check"
                                  data-action="videoOnlyGrid"
                                  data-computer={backgroundName}
                                  data-tab-id={tabNumber}
                                  id="videoOnlyGrid{backgroundName}{tabNumber}"
                                  type="checkbox"
                                  disabled={!tabInfo.currentState.inCall}
                                  checked={tabInfo.currentState.videoOnlyGrid}
                                /><label
                                  class="btn btn-outline-primary btn-sm"
                                  for="videoOnlyGrid{backgroundName}{tabNumber}"
                                >
                                  Video Only Grid
                                </label>
                              </div>
                              <div class="btn-group me-2" role="group">
                                <input
                                  on:change={inputChange}
                                  class="btn-check"
                                  data-action="toggleCamera"
                                  id="camera{backgroundName}{tabNumber}"
                                  data-computer={backgroundName}
                                  data-tab-id={tabNumber}
                                  type="checkbox"
                                  disabled={!tabInfo.currentState.inCall}
                                  checked={tabInfo.currentState.cameraEnabled}
                                /><label
                                  class="btn btn-outline-primary btn-sm"
                                  for="camera{backgroundName}{tabNumber}"
                                >
                                  {#if tabInfo.currentState.cameraEnabled}
                                    Camera On
                                  {:else}
                                    Camera Off
                                  {/if}
                                </label>
                                <input
                                  on:change={inputChange}
                                  class="btn-check"
                                  data-action="toggleMicrophone"
                                  id="microphone{backgroundName}{tabNumber}"
                                  data-computer={backgroundName}
                                  data-tab-id={tabNumber}
                                  type="checkbox"
                                  disabled={!tabInfo.currentState.inCall}
                                  checked={tabInfo.currentState.micEnabled}
                                /><label
                                  class="btn btn-outline-primary btn-sm"
                                  for="microphone{backgroundName}{tabNumber}"
                                >
                                  {#if tabInfo.currentState.micEnabled}
                                    Mic On
                                  {:else}
                                    Mic Off
                                  {/if}
                                </label>
                                <input
                                  on:change={inputChange}
                                  class="btn-check"
                                  data-action="neverMute"
                                  id="neverMute{backgroundName}{tabNumber}"
                                  data-computer={backgroundName}
                                  data-tab-id={tabNumber}
                                  type="checkbox"
                                  checked={tabInfo.currentState.neverMute}
                                /><label
                                  class="btn btn-outline-primary btn-sm"
                                  for="neverMute{backgroundName}{tabNumber}"
                                >
                                  {#if tabInfo.currentState.neverMute}
                                    Never Mute
                                  {:else}
                                    Never Mute Off
                                  {/if}
                                </label>
                              </div>
                            </div>
                            {#if !tabInfo.currentState.inCall && tabInfo.currentState.meetURL}
                              <button
                                on:click={() =>
                                  commandTab("clickJoinMeet", backgroundName, tabNumber)}
                                class="btn btn-success action">Click Join Meet</button
                              >
                            {/if}
                            <button
                              on:click={() =>
                                commandTab("debug", backgroundName, tabNumber)}
                              class="btn btn-info">Log Debug</button
                            >
                            <button
                              on:click={() => closeTab(backgroundName, tabNumber)}
                              class="btn btn-danger action">Close Tab</button
                            >
                          </div>
                          <button
                            on:click={() =>
                              commandTab("regularVideo", backgroundName, tabNumber)}
                            class="btn btn-secondary btn-lg mt-2 action"
                            disabled={!tabInfo.currentState.pinned}
                          >
                            {#if tabInfo.currentState.pinned}
                              Un-take {tabInfo.currentState.pinned}
                            {:else}
                              No Full Video
                            {/if}
                          </button>
                          <div
                            class="accordion"
                            id="{backgroundName}{tabNumber}UsersAccordion"
                          >
                            <div class="accordion-item">
                              <h2
                                class="accordion-header"
                                id="{backgroundName}{tabNumber}UsersAccordion-userListheading"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#{backgroundName}{tabNumber}UsersAccordion-userListCollapse"
                                  aria-expanded="false"
                                  aria-controls="{backgroundName}{tabNumber}UsersAccordion-userListCollapse"
                                >
                                  User List and Control
                                </button>
                              </h2>
                              <div
                                id="{backgroundName}{tabNumber}UsersAccordion-userListCollapse"
                                class="accordion-collapse collapse"
                                aria-labelledby="{backgroundName}{tabNumber}UsersAccordion-userListheading"
                              >
                                <div
                                  class="accordion-body overflow-auto"
                                  style="max-height:50%"
                                />
                                <table
                                  class="table table-striped table-hover"
                                  data-computer={backgroundName}
                                  data-tab-id={tabNumber}
                                >
                                  <thead class="bg-info"
                                    ><tr
                                      ><th class="sort" data-sort="username">Username</th
                                      ><th class="sort" data-sort="cameraEnabled"
                                        >Camera Status</th
                                      ><th>Actions</th></tr
                                    ></thead
                                  ><tbody
                                    class="list"
                                    data-computer={backgroundName}
                                    data-tab-id={tabNumber}
                                  >
                                    {#if tabInfo.users}
                                      {#each Object.entries(tabInfo.users) as [username, userData]}
                                        <tr
                                          class="m-1"
                                          data-user={username}
                                          data-computer={backgroundName}
                                          data-tab-id={tabNumber}
                                          ><td class="username">{username}</td>
                                          {#if userData.isPresentation}
                                            <td>Presentation</td>
                                          {:else}
                                            <td>
                                              {#if userData.cameraEnabled}
                                                <svg
                                                  class="bi bg-success inline"
                                                  width="32"
                                                  height="32"
                                                  fill="currentColor"
                                                  aria-label="Camera Enabled"
                                                >
                                                  <use
                                                    xlink:href="lib/img/bootstrap-icons.svg#camera-video"
                                                  />
                                                </svg>
                                              {:else}
                                                <svg
                                                  class="bi bg-danger inline"
                                                  width="32"
                                                  height="32"
                                                  fill="currentColor"
                                                  aria-label="Camera Disabled"
                                                >
                                                  <use
                                                    xlink:href="lib/img/bootstrap-icons.svg#camera-video-off"
                                                  />
                                                </svg>
                                              {/if}
                                              {#if userData.muted}
                                                <svg
                                                  class="bi bg-danger inline"
                                                  width="32"
                                                  height="32"
                                                  fill="currentColor"
                                                  aria-label="Microphone Disabled"
                                                >
                                                  <use
                                                    xlink:href="lib/img/bootstrap-icons.svg#mic-mute-fill"
                                                  />
                                                </svg>
                                              {:else if userData.talking}
                                                <svg
                                                  class="bi bg-success inline"
                                                  width="32"
                                                  height="32"
                                                  fill="currentColor"
                                                  aria-label="User is talking"
                                                >
                                                  <use
                                                    xlink:href="lib/img/bootstrap-icons.svg#mic-fill"
                                                  />
                                                </svg>
                                              {:else}
                                                <svg
                                                  class="bi bg-warning inline"
                                                  width="32"
                                                  height="32"
                                                  fill="currentColor"
                                                  aria-label="Microphone Enabled"
                                                >
                                                  <use
                                                    xlink:href="lib/img/bootstrap-icons.svg#mic-fill"
                                                  />
                                                </svg>
                                              {/if}
                                            </td>{/if}<td
                                            ><input
                                              on:change={inputChange}
                                              class="btn-check"
                                              data-action="fullVideoUser"
                                              id="fullVideoUser{backgroundName}{tabNumber}{username}"
                                              data-computer={backgroundName}
                                              data-tab-id={tabNumber}
                                              data-user={username}
                                              type="checkbox"
                                              checked={tabInfo.currentState.pinned ===
                                                username}
                                            />
                                            <label
                                              class="btn btn-outline-success"
                                              for="fullVideoUser{backgroundName}{tabNumber}{username}"
                                            >
                                              {#if tabInfo.currentState.pinned === username}
                                                Un-take
                                              {:else}
                                                Take full
                                              {/if}
                                            </label>
                                            <input
                                              on:change={inputChange}
                                              class="btn-check"
                                              data-action="toggleExcludeUser"
                                              id="toggleExcludeUser{backgroundName}{tabNumber}{username}"
                                              data-computer={backgroundName}
                                              data-tab-id={tabNumber}
                                              data-user={username}
                                              type="checkbox"
                                              checked={userData.exclude}
                                            /><label
                                              class="btn btn-outline-primary btn-sm"
                                              for="toggleExcludeUser{backgroundName}{tabNumber}{username}"
                                              >{userData.exclude
                                                ? "Un-Exclude from grid"
                                                : "Exclude From Grid"}</label
                                            ></td
                                          ></tr
                                        >
                                      {/each}
                                    {/if}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {#if tabInfo.currentState.chat}
                              <div class="accordion-item">
                                <h2
                                  class="accordion-header"
                                  id="{backgroundName}ChatAccordion-heading"
                                >
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#{backgroundName}{tabNumber}ChatAccordion-collapse"
                                    aria-expanded="false"
                                    aria-controls="{backgroundName}{tabNumber}ChatAccordion-collapse"
                                  >
                                    Chat(Beta)
                                  </button>
                                </h2>
                                <div
                                  id="{backgroundName}{tabNumber}ChatAccordion-collapse"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="{backgroundName}{tabNumber}ChatAccordion-heading"
                                >
                                  <div
                                    class="accordion-body overflow-auto"
                                    style="max-height:25vh;
                  flex-direction: column-reverse;
                  display: flex;"
                                  >
                                    <div id="{backgroundName}{tabNumber}ChatDiv">
                                      <table class="table table-striped table-responsive">
                                        <thead>
                                          <tr>
                                            <th class="w-25">User</th>
                                            <th>Time</th>
                                            <th class="w-75">Message</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <!--{#each tabInfo.currentState.chat as chat}
                                              {#if chat.message && chat.message !== ""}
                                                <tr>
                                                  <td>{chat.sender}</td>
                                                  <td>{chat.time}</td>
                                                  <td>{chat.message}</td>
                                                </tr>
                                              {/if}
                                            {/each}-->
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            {/if}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                {/each}
              {:else}
                <div class="alert alert-warning">
                  No Google Meet tabs open. Submit one above
                </div>
              {/if}
              {#if backgroundData.settings}
                <div class="accordion-item">
                  <h2
                    class="accordion-header"
                    id="{backgroundName}SettingsAccordion-heading"
                  >
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#{backgroundName}SettingsAccordion-collapse"
                      aria-expanded="false"
                      aria-controls="{backgroundName}SettingsAccordion-collapse"
                    >
                      Settings
                    </button>
                  </h2>
                  <div
                    id="{backgroundName}SettingsAccordion-collapse"
                    class="accordion-collapse collapse"
                    aria-labelledby="{backgroundName}SettingsAccordion-heading"
                  >
                    <div class="accordion-body overflow-auto">
                      <div id="{backgroundName}SettingsDiv">
                        <table class="table table-striped table-responsive">
                          <tbody>
                            <tr colspan="2"
                              ><button
                                on:click={() => updateSettings(backgroundName)}
                                class="btn btn-success">Update Settings</button
                              ></tr
                            >
                            {#each Object.entries(backgroundData.settings) as [key, value]}
                              {#if key !== "accept_eula"}
                                <tr>
                                  <td>{key}</td>
                                  {#if typeof value === "boolean" || value === "false" || value === "true"}
                                    <td
                                      ><input
                                        type="checkbox"
                                        checked={value === "true" || value === true}
                                        data-computer={backgroundName}
                                        data-settings-name={key}
                                        disabled={key === "accept_eula"}
                                      /></td
                                    >
                                  {:else if typeof value === "number"}
                                    <td
                                      ><input
                                        type="number"
                                        class="form-control"
                                        data-computer={backgroundName}
                                        data-settings-name={key}
                                        {value}
                                      /></td
                                    >
                                  {:else if typeof value === "string"}
                                    <td
                                      ><input
                                        type="text"
                                        class="form-control"
                                        data-computer={backgroundName}
                                        data-settings-name={key}
                                        {value}
                                      /></td
                                    >
                                  {:else if Array.isArray(value)}
                                    <td
                                      ><input
                                        type="text"
                                        class="form-control"
                                        data-computer={backgroundName}
                                        data-settings-name={key}
                                        value={value.join(",")}
                                      /></td
                                    >
                                  {/if}
                                </tr>
                              {/if}
                            {/each}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              {/if}
            </div>
          </div>
        {/each}
      </div>
      <div class="h-16">&nbsp</div>
      <div on:dragover={handleDrag} class="relative w-full max-h-fit">
        <div class="opacity-75" />
        <div
          class="w-full h-full d-none z-40 absolute text-center content-center text-xl"
          bind:this={dragArea}
          on:drop={handleDrop}
          on:dragend={handleDragLeave}
          on:dragleave={handleDragLeave}
        />
        <div class="flex justify-center">
          <label class="w-full md:w-3/4 text-center">
            <label for="massMeetOpenInput" />
            <div class="text-md md:text-lg">
              Drag and drop or upload a .csv or .xlsx file with a "Computer" column and a
              "Meet" column to open meets on multiple computers at once
            </div>
            <label for="csvOutput">.csv</label>
            <input
              id="csvOutput"
              type="checkbox"
              class="form-check-input"
              bind:checked={outputTypes.csv}
            />
            <label for="xlsxOutput">.xlsx</label>
            <input
              id="xlsxOutput"
              type="checkbox"
              class="form-check-input"
              bind:checked={outputTypes.xlsx}
            />
            <label for="txtOutput">.txt</label>
            <input
              id="txtOutput"
              type="checkbox"
              class="form-check-input"
              bind:checked={outputTypes.txt}
            />
            <label for="rtfOutput">.rtf</label>
            <input
              id="rtfOutput"
              type="checkbox"
              class="form-check-input"
              bind:checked={outputTypes.rtf}
            />
            <button on:click={genExampleFiles} class="btn btn-primary"
              >Download Example Files</button
            ><button on:click={genEmptyFiles} class="btn btn-secondary"
              >Download Template Files</button
            ></label
          >
          <input
            id="massMeetOpenInput"
            class="form-control"
            type="file"
            accept=".csv,.xlsx,.txt,.rtf"
            bind:this={massMeetOpenInput}
            bind:files
          />
          {#if files && files[0]}
            <p>
              {files[0].name}
            </p>
          {/if}
        </div>
      </div>
      {#if openMeetList.length > 0}
        <table class="table table-striped table-responsive w-100">
          <thead>
            <tr>
              <th>Computer Name</th>
              <th>Meet Code</th>
              <th>Fullscreen</th>
              <th>Regular Window</th>
            </tr>
          </thead>
          <tbody>
            {#each openMeetList as openMeet}
              <tr>
                <td class={openMeet.invalidComputerName ? "bg-danger" : ""}
                  >{#if openMeet.invalidComputerName}
                    Invalid Computer Name
                  {/if}
                  <input type="text" bind:value={openMeet.computerName} /></td
                >
                <td class={openMeet.invalidMeetCode ? "bg-danger" : ""}
                  >{#if openMeet.invalidMeetCode}
                    Invalid Meet Code
                  {/if}<input type="text" bind:value={openMeet.meetCode} /></td
                >
                <td
                  ><input
                    class="form-check-input"
                    type="checkbox"
                    bind:checked={openMeet.fullscreen}
                  /></td
                >
                <td
                  ><input
                    class="form-check-input"
                    type="checkbox"
                    bind:checked={openMeet.regularWindow}
                  /></td
                >
              </tr>
            {/each}
            <tr>
              <td colspan="4" class="text-center"
                ><button
                  class="btn btn-success btn-lg w-1/3"
                  on:click={() => {
                    openMeetList
                      .filter(
                        (item) => !item.invalidComputerName && !item.invalidMeetCode
                      )
                      .forEach((item, index) => {
                        setTimeout(() => {
                          openNewMeetHelper(item.computerName, item.meetCode);
                          openMeetList.splice(index, 1);
                        }, index * 20);
                      });
                    openMeetList = openMeetList;
                  }}>Submit</button
                ><button
                  class="btn btn-primary btn-lg w-1/3"
                  on:click={() => {
                    massMeetOpenInput.value = "";
                    openMeetList = [];
                  }}>Reset</button
                ></td
              >
            </tr>
          </tbody>
        </table>
      {/if}
    {/if}
  </div>

  <table class="table table-striped table-responsive" id="masterTable">
    <tbody id="masterTBody" />
  </table>
</main>
