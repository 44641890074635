<script lang="ts">
  import { push, pop, replace } from "svelte-spa-router";
  import {
    Auth,
    signInWithEmailAndPassword,
    signInWithRedirect,
    signInWithPopup,
    GoogleAuthProvider,
  } from "firebase/auth";
  import { isMobile } from "is-mobile";
  import { user } from "../stores/firebase";

  $: if ($user?.uid) {
    replace("/");
  }

  export let auth: Auth;

  const provider = new GoogleAuthProvider();

  function googleSignIn() {
    if (isMobile()) {
      signInWithRedirect(auth, provider);
    } else {
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          replace("/");
        });
    }
  }

  function login(event: SubmitEvent) {
    event.preventDefault();
    const password = document.forms["loginForm"]["password"].value;
    const email = document.forms["loginForm"]["email"].value;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        replace("/");

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
      });
  }
</script>

<svelte:head>
  <title>MeetManage Login</title>
</svelte:head>
<main>
  <div class="wrapper container">
    <div class="d-flex justify-content-center">
      <h2>Login</h2>
    </div>
    <div class="d-flex justify-content-center">
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a class="btn btn-secondary m-auto" href="#" on:click|preventDefault={googleSignIn}>
        Sign in with Google
      </a>
    </div>
    <div class="d-flex justify-content-center">
      <p>Please fill in your credentials to login.</p>
    </div>
    <div class="d-flex justify-content-center">
      <form id="loginForm" on:submit={login}>
        <div class="form-group">
          <label for="email">Email</label><input
            id="email"
            class="form-control"
            type="email"
            name="email"
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label><input
            id="password"
            class="form-control"
            type="password"
            name="password"
            minlength="8"
            title="8 characters minimum"
            value=""
          /><span class="help-block" />
        </div>
        <div class="form-group">
          <input class="btn btn-primary w-full my-2" type="submit" value="Login" />
        </div>
        <span class="help-block" />
        <p>Don&apos;t have an account? <a href="/#/register">Sign up now</a>.</p>
      </form>
    </div>
  </div>
</main>
