<script lang="ts">
  import { user, currentRole, privileges } from "../stores/firebase.js";
  import {
    collection,
    addDoc,
    onSnapshot,
    where,
    query,
    doc,
    deleteDoc,
    updateDoc,
    Firestore,
    serverTimestamp,
  } from "firebase/firestore";
  import { replace } from "svelte-spa-router";

  export let firestore: Firestore;
  interface Tokens {
    [key: string]: { identifier: string; userCount: number };
  }

  let tokens: Tokens = {};

  let editingToken = "";

  let searchQuery = "";

  let viewingTokens: Tokens = {};

  let identifierFieldValue = "";

  const tokenCollection = collection(firestore, "tokens");

  if (!$user?.uid) {
    replace("/");
  } else {
    const q = query(tokenCollection, where("uid", "==", $user.uid));
    onSnapshot(q, (snapshot) => {
      tokens = snapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
      }, {});
    });
  }
  async function genToken() {
    if (Object.keys(tokens).length + 1 > $privileges[$currentRole].tokens) {
      alert(
        "You have reached the maximum number of tokens. Please upgrade your plan to generate more tokens."
      );
      return;
    }
    try {
      const docRef = await addDoc(tokenCollection, {
        identifier: identifierFieldValue || "N/A",
        uid: $user.uid,
        userCount: 0,
        created: serverTimestamp(),
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      alert(e);
    }
    clearFields();
  }

  async function deleteToken(token: string) {
    if (confirm("Are you sure you want to delete this token?")) {
      try {
        await deleteDoc(doc(firestore, "tokens", token));
      } catch (e) {
        alert(e);
      }
    }
  }

  async function editToken() {
    try {
      await updateDoc(doc(firestore, "tokens", editingToken), {
        identifier: identifierFieldValue || "N/A",
        updated: serverTimestamp(),
      });
    } catch (e) {
      console.warn(e, "tokens", editingToken, {
        identifier: identifierFieldValue || "N/A",
        updated: serverTimestamp(),
      });
      alert(e);
    }
    clearFields();
  }

  function clearFields() {
    editingToken = "";
    identifierFieldValue = "";
  }

  function editTokenHelper(token: string, identifier: string) {
    identifierFieldValue = identifier;
    editingToken = token;
  }

  $: viewingTokens = Object.keys(tokens)
    .filter((key) =>
      searchQuery !== ""
        ? tokens[key].identifier.toLowerCase().includes(searchQuery.toLowerCase()) ||
          key.toLowerCase().includes(searchQuery.toLowerCase())
        : true
    )
    .reduce((res, key) => ((res[key] = tokens[key]), res), {});
</script>

<div class="container ps-0" id="tokenDiv">
  <div class="d-flex justify-content-center p-3">
    <input
      bind:value={searchQuery}
      class="search form-control"
      type="text"
      id="searchBox"
      placeholder="Search table"
    />
  </div>
  <table class="table table-striped table-hover flex-fill">
    <thead
      ><tr class="bg-info"
        ><th role="button">Token Identifier</th><th role="button">Token</th><th
          role="button">Connections</th
        ><th colspan="2" /></tr
      ></thead
    ><tbody class="list"
      >{#if viewingTokens}{#each Object.entries(viewingTokens) as [token, data]}<tr
            ><td>{data.identifier}</td><td
              ><button
                on:click={() => navigator.clipboard.writeText(token)}
                class="btn btn-sm btn-primary">Copy</button
              ><a href="/#/panel/{token}">{token}</a></td
            ><td>{data.userCount}/{$privileges[$currentRole].computers}</td><td
              ><button
                on:click={() => editTokenHelper(token, data.identifier)}
                class="edit-item-btn btn btn-sm btn-primary">Edit</button
              ></td
            >
            <td
              >{#if data.userCount > 0}
                <button
                  on:click={() => alert("Please remove computers from the token first.")}
                  class="remove-item-btn btn btn-sm btn-danger">In use!</button
                >
              {:else}
                <button
                  on:click={() => deleteToken(token)}
                  class="remove-item-btn btn btn-sm btn-danger">Remove</button
                >
              {/if}
            </td></tr
          >
        {/each}
      {/if}
    </tbody>
  </table>
</div>
<div class="accordion accordion-flush w-full md:w-3/4 m-auto" id="websocketsAccordion">
  <div class="accordion-item">
    <h2 class="accordion-header bg-secondary border" id="headingOne">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="false"
        aria-controls="collapseOne"
      >
        Legacy Socketio Settings Instructions
      </button>
    </h2>
    <div
      id="collapseOne"
      class="accordion-collapse collapse"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div class="accordion-body">
        <div class="w-full mb-1">
          <div class="m-auto w-3/4 md:w-1/2 lg:w-1/3">
            Update each extension's settings with:
            <div class="list-group">
              <div class="list-group-item">A unique computer name</div>
              <button
                on:click={() => navigator.clipboard.writeText("https://mmws.ferum.tech")}
                class="list-group-item text-left"
                >The server address of https://mmws.ferum.tech (click to copy)</button
              >
              <div class="list-group-item">One of the above tokens</div>
            </div>
          </div>
        </div>
        <div
          class="border border-5 border-primary m-auto p-4 rounded w-full md:w-3/4 lg:w-1/2 cursor-not-allowed relative"
        >
          <div
            class="w-full h-full opacity-10 hover:opacity-75 absolute top-0 left-0 bg-black text-center"
          >
            Click on the Meet Manage extension and fill out these settings
          </div>
          <table class="table table-striped table-bordered">
            <tbody>
              <tr>
                <td>
                  <label for="computerNameId">Computer Name: </label>
                </td>
                <td colspan="2">
                  <input
                    class="form-control cursor-not-allowed"
                    id="computerNameId"
                    data-settings-name="computer_name"
                    type="text"
                    value="UNIQUE_COMPUTER_NAME"
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label for="socketioUrlInput">Server: </label><br />
                  <a href="https://galli.dev" target="_blank">Freemium server</a><br />
                  <a href="https://meetmanage.ferum.tech" target="_blank"
                    >Beta freemium server</a
                  ><br />
                  <a href="https://github.com/kgallimore/meetManagePublic" target="_blank"
                    >API to create your own server here</a
                  >
                </td>
                <td colspan="2">
                  <input
                    class="form-control cursor-not-allowed"
                    id="socketioUrlInput"
                    data-settings-name="socketio_url"
                    type="url"
                    value="https://mmws.ferum.tech"
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label for="socketioTokenInput">Server Token: </label>
                </td>
                <td colspan="2">
                  <input
                    class="form-control cursor-not-allowed"
                    id="socketioTokenInput"
                    data-settings-name="socketio_token"
                    type="text"
                    value="ABOVE_TOKEN_VALUE"
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="h-24" />
<footer class="fixed-bottom bg-secondary" style="z-index: 49;">
  <div class="container-fluid">
    <table class="table">
      <thead><tr><th colspan="4">Create/Edit token</th></tr></thead><tbody
        ><tr
          ><td class="w-25">Token:<span id="tokenField" />{editingToken}</td><td
            class="w-25"
            ><input
              type="text"
              class="form-control"
              maxlength="50"
              placeholder="Identifier"
              id="tokenIdentifierField"
              bind:value={identifierFieldValue}
            /></td
          ><td class="w-25"
            >{#if editingToken !== ""}<button
                on:click={editToken}
                class="btn btn-primary"
                id="edit-btn">Edit Token</button
              >{:else}<button on:click={genToken} class="btn btn-success" id="add-btn"
                >Add New Token</button
              >{/if}
            {Object.keys(tokens).length.toString() +
              "/" +
              ($privileges[$currentRole]?.tokens.toString() ?? 2)} Tokens</td
          ><td class="w-25"
            ><button on:click={clearFields} class="btn btn-primary" id="clear-btn"
              >Clear fields</button
            ></td
          ></tr
        ></tbody
      >
    </table>
  </div>
</footer>
