<svelte:head>
  <title>MeetManage</title>
</svelte:head>
<div class="d-flex justify-content-center align-items-center container">
  <div class="carousel slide w-75" id="indexCarousel" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button
        class="active"
        type="button"
        data-bs-target="#indexCarousel"
        data-bs-slide-to="0"
        aria-current="true"
        aria-label="Slide 1"
      /><button
        type="button"
        data-bs-target="#indexCarousel"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      /><button
        type="button"
        data-bs-target="#indexCarousel"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      /><button
        type="button"
        data-bs-target="#indexCarousel"
        data-bs-slide-to="3"
        aria-label="Slide 4"
      />
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          class="d-block w-100"
          src="img/promos/normal.webp"
          alt="Normal Looking Google Meet"
        />
        <div
          class="d-none d-sm-block text-light bg-secondary rounded text-center m-2 pb-3 pt-2"
        >
          <h5>Default Google Meet</h5>
          <p>Standard look with all elements</p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="img/promos/hide_elements.webp"
          alt="Google Meet with hidden interface"
        />
        <div
          class="d-none d-sm-block text-light bg-secondary rounded text-center m-2 pb-3 pt-2"
        >
          <h5>Hidden elements</h5>
          <p>Easily hide all non-video elements</p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="img/promos/video_only_grid.webp"
          alt="Google Meet with only video participants shown"
        />
        <div
          class="d-none d-sm-block text-light bg-secondary rounded text-center m-2 pb-3 pt-2"
        >
          <h5>Show only video participants</h5>
          <p>Toggle between standard view and video only participant view.</p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="img/promos/management_interfacev2.webp"
          alt="MeetManage management interface"
        />
        <div
          class="d-none d-sm-block text-light bg-secondary rounded text-center m-2 pb-3 pt-2"
        >
          <h5>Manage multiple computers from one place</h5>
          <p>Access and control multiple computers from one or more panels.</p>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#indexCarousel"
      data-bs-slide="prev"
      ><span class="carousel-control-prev-icon" aria-hidden="true" /><span
        class="visually-hidden">Previous</span
      ></button
    ><button
      class="carousel-control-next"
      type="button"
      data-bs-target="#indexCarousel"
      data-bs-slide="next"
      ><span class="carousel-control-next-icon" aria-hidden="true" /><span
        class="visually-hidden">Next</span
      ></button
    >
  </div>
</div>
<div class="container px-4 py-5">
  <h2 class="pb-2 border-bottom">Feature Set</h2>
  <div class="row g-4 py-5 row-cols-1 row-cols-lg-2">
    <div class="feature col">
      <h2>Server Features</h2>
      <p>
        Add another layer of control through a powerful management dashboard, allowing
        easy control of multiple computers running the standard extension
      </p>
      <a class="icon-link" href="/register">You can sign up for an account here</a>
    </div>
    <div class="feature col">
      <h2>Extension features</h2>
      <p>
        Use various keyboard shortcuts and settings to manipulate the look of Google Meet
      </p>
      <a
        class="icon-link"
        href="https://chrome.google.com/webstore/detail/meet-manage/nodegnhodiddibeohajmcamhgblbgblc"
        >You can get the extension here</a
      >
    </div>
  </div>
</div>
<div class="container px-4 py-5">
  <h2 class="pb-2 border-bottom">Server Features</h2>
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
    <div class="col d-flex align-items-start">
      <svg
        class="bi text-muted flex-shrink-0 me-3"
        width="32"
        height="32"
        fill="currentColor"><use href="lib/img/bootstrap-icons.svg#border-all" /></svg
      >
      <div>
        <h4 class="fw-bold mb-0">Create Computer Groups</h4>
        <p>
          Create individual tokens to separate groups of computers, have different
          controllers control those groups
        </p>
      </div>
    </div>
    <div class="col d-flex align-items-start">
      <svg
        class="bi text-muted flex-shrink-0 me-3"
        width="32"
        height="32"
        fill="currentColor"><use href="lib/img/bootstrap-icons.svg#list" /></svg
      >
      <div>
        <h4 class="fw-bold mb-0">List all participants</h4>
        <p>See a list of all current meeting participants</p>
      </div>
    </div>
    <div class="col d-flex align-items-start">
      <svg
        class="bi text-muted flex-shrink-0 me-3"
        width="32"
        height="32"
        fill="currentColor"
        ><use href="lib/img/bootstrap-icons.svg#camera-video-off" /></svg
      >
      <div>
        <h4 class="fw-bold mb-0">See Camera Status</h4>
        <p>See the status of each participants camera status</p>
      </div>
    </div>
    <div class="col d-flex align-items-start">
      <svg
        class="bi text-muted flex-shrink-0 me-3"
        width="32"
        height="32"
        fill="currentColor"><use href="lib/img/bootstrap-icons.svg#camera" /></svg
      >
      <div>
        <h4 class="fw-bold mb-0">Pin Individual Participant</h4>
        <p>Pin a participant member by name</p>
      </div>
    </div>
    <div class="col d-flex align-items-start">
      <svg
        class="bi text-muted flex-shrink-0 me-3"
        width="32"
        height="32"
        fill="currentColor"><use href="lib/img/bootstrap-icons.svg#trash" /></svg
      >
      <div>
        <h4 class="fw-bold mb-0">Hide Elements</h4>
        <p>Easily toggle all non video elements</p>
      </div>
    </div>
    <div class="col d-flex align-items-start">
      <svg
        class="bi text-muted flex-shrink-0 me-3"
        width="32"
        height="32"
        fill="currentColor"
        ><use href="lib/img/bootstrap-icons.svg#arrows-fullscreen" /></svg
      >
      <div>
        <h4 class="fw-bold mb-0">Toggle Fullscreen</h4>
        <p>Easily toggle between full screen and windowed</p>
      </div>
    </div>
    <div class="col d-flex align-items-start">
      <svg
        class="bi text-muted flex-shrink-0 me-3"
        width="32"
        height="32"
        fill="currentColor"><use href="lib/img/bootstrap-icons.svg#pencil-square" /></svg
      >
      <div>
        <h4 class="fw-bold mb-0">View and Change Meeting Code</h4>
        <p>View each computers meeting code and be able to change it</p>
      </div>
    </div>
    <div class="col d-flex align-items-start">
      <svg
        class="bi text-muted flex-shrink-0 me-3"
        width="32"
        height="32"
        fill="currentColor"><use href="lib/img/bootstrap-icons.svg#grid-3x2" /></svg
      >
      <div>
        <h4 class="fw-bold mb-0">Custom Video Grid(WIP)</h4>
        <p>
          Enable a custom video grid that takes up the whole screen, with the option to
          only show video participants
        </p>
      </div>
    </div>
  </div>
</div>
