<h3>Terms of Service</h3>
<p>
  Prices and restrictions are subject to change. Usage must be in accordance with federal
  or state law, whichever takes precedence. Abuse of this service is not tolerated and
  your account may be terminated at any time if you are believed to be doing so. We
  reserve the right to terminate your account for any reason.
</p>
<h3>Privacy</h3>
<p>
  Your data is not sold to any third parties. Your email used to login will be stored for
  login purposes, but you will not receive any marketing emails. Any data is transmitted
  to, and potentially stored, using Firebase. Data may be shared with Stripe in order to
  process payments. Google Analytics is used to track usage and usage patterns, and beyond
  that all other data gathered is strictly for usage functionality.
</p>
