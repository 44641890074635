<script lang="ts">
  import "./app.css";

  import Router, { replace } from "svelte-spa-router";
  import { wrap } from "svelte-spa-router/wrap";
  import Home from "./routes/home.svelte";
  import Login from "./routes/login.svelte";
  import Panel from "./routes/panel.svelte";
  import Register from "./routes/register.svelte";
  import MasterPanel from "./routes/masterPanel.svelte";
  import Missing from "./routes/404.svelte";
  import Account from "./routes/account.svelte";
  import Test from "./routes/test.svelte";
  import EULA from "./routes/EULA.svelte";

  import Logo from "./lib/__logo.svelte";
  import Loading from "./lib/__loading.svelte";

  import { fade } from "svelte/transition";
  import { expoIn } from "svelte/easing";

  import {
    getAuth,
    onAuthStateChanged,
    signOut,
    sendEmailVerification,
    connectAuthEmulator,
  } from "firebase/auth";
  import {
    getFirestore,
    collection,
    doc,
    serverTimestamp,
    setDoc,
    Unsubscribe,
    onSnapshot,
    connectFirestoreEmulator,
  } from "firebase/firestore";
  import { getDatabase, ref, onValue, connectDatabaseEmulator } from "firebase/database";
  import { initializeApp } from "firebase/app";
  import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
  import { getAnalytics } from "firebase/analytics";

  import { currentRole, user, privileges } from "./stores/firebase.js";

  let firebaseConfig = {
    apiKey: "AIzaSyAoWo_YdNe-Hn-sAdYwVPfi6VV96KqYKmI",
    authDomain: "meetmanage-5f778.firebaseapp.com",
    projectId: "meetmanage-5f778",
    storageBucket: "meetmanage-5f778.appspot.com",
    databaseURL: "https://meetmanage-5f778-default-rtdb.firebaseio.com/",
    messagingSenderId: "312866032081",
    appId: "1:312866032081:web:e3a4b6e2dce20816a7f8f0",
    measurementId: "G-981RCH3WSK",
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const db = getDatabase(app);

  if (
    window.location.hostname !== "localhost" &&
    window.location.hostname !== "127.0.0.1"
  ) {
    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider("6LdOF6gcAAAAAAX5YQbdMaFWfP8RZvX76zCZjrQh"),
      isTokenAutoRefreshEnabled: true,
    });
  } else {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectDatabaseEmulator(db, "localhost", 9000);
    connectFirestoreEmulator(firestore, "localhost", 8080);
  }

  let loading = true;
  let emailTimer: NodeJS.Timeout;

  if (new URLSearchParams(window.location.search).get("emailVerified") === "true") {
    $user.getIdToken(true);
  }

  let wsAvaialable = false;
  let checkPrivileges: Unsubscribe;

  onValue(ref(db, "ws/available"), (snapshot) => {
    console.log("ws/available", snapshot.val());
    wsAvaialable = snapshot.val() === true;
  });
  onSnapshot(collection(firestore, "roles"), (snapshot) => {
    snapshot.docs.forEach((doc) => {
      if (doc.data()) {
        $privileges[doc.id] = doc.data() as {
          computers: number;
          tokens: number;
          price: number;
        };
      }
    });
  });
  onAuthStateChanged(auth, (authed) => {
    loading = false;
    if (authed) {
      $user = authed;
      checkPrivileges = onSnapshot(doc(firestore, "users/" + $user.uid), (snapshot) => {
        if (snapshot.exists() && snapshot.data()?.role) {
          $currentRole = snapshot.data().role;
          if (
            snapshot.data().refreshTime &&
            snapshot.data().refreshTime.toMillis() + 5000 > Date.now()
          ) {
            alert("Your account status has changed");
            $user.getIdToken(true);
          }
        } else {
          console.log("User not set up yet");
        }
      });
      authed.getIdToken().then((token) => {
        console.log(token);
      });
    } else {
      if (checkPrivileges) {
        checkPrivileges();
      }
      $user = null;
    }
  });

  const routes = {
    "/": Home,
    "/login": wrap({
      component: Login,
      props: {
        auth,
      },
      conditions: [
        () => {
          return $user == null || $user.uid == null;
        },
      ],
    }),
    "/register": wrap({
      component: Register,
      props: {
        auth,
      },
      conditions: [
        () => {
          return $user == null || $user.uid == null;
        },
      ],
    }),
    "/panel": wrap({
      component: MasterPanel,
      props: {
        firestore,
      },
      conditions: [
        () => {
          return $user !== null && $user.uid !== null && $user.emailVerified;
        },
      ],
    }),
    "/panel/:token": wrap({
      component: Panel,
      props: {
        db,
      },
      conditions: [
        () => {
          return $user !== null && $user.uid !== null && $user.emailVerified;
        },
      ],
    }),
    "/account": wrap({
      component: Account,
    }),
    "/EULA": wrap({
      component: EULA,
    }),
    "/test": wrap({
      component: Test,
    }),
    "*": Missing,
  };
  // @ts-ignore
  let inDark = darkmode.inDarkMode;

  function sendVerifyEmail() {
    if (!emailTimer) {
      emailTimer = setTimeout(() => {
        clearTimeout(emailTimer);
        emailTimer = null;
      }, 5000);
      sendEmailVerification($user, {
        url: window.location.origin + "/#/?emailVerified=true",
      })
        .then(function () {
          console.log("Email sent.");
        })
        .catch(function (error) {
          console.log("Error sending email:", error);
        });
    }
  }
  function conditionsFailed(event) {
    console.error(
      "conditionsFailed event",
      event.detail,
      $user !== null,
      $user?.uid == null && $user?.emailVerified
    );
    replace("/");
  }
  function logout() {
    signOut(auth);
  }
  function toggleDarkMode() {
    // @ts-ignore
    darkmode.setDarkMode(!darkmode.inDarkMode);
    // @ts-ignore
    inDark = darkmode.inDarkMode;
  }
</script>

<main>
  {#if loading}
    <div
      class="flex items-center h-full w-full bg-black absolute z-50"
      out:fade={{ duration: 500, easing: expoIn }}
    >
      <div class="text-center text-7xl w-full">
        <span class="text-red-theme" style="font-family: 'Nova Square', cursive;"
          >MeetManage.</span
        ><Logo />
        {#if false}
          <div class="text-red-theme text-center text-6xl">
            <span>{"test"}</span>
          </div>
        {:else}
          {#key !false}
            <div class="flex justify-center" out:fade={{ duration: 100 }}>
              <Loading />
            </div>
          {/key}
        {/if}
      </div>
    </div>
  {:else}
    <div class="p-1">
      <nav
        class="navbar navbar-expand-lg navbar-light bg-light sticky-top"
        style="z-index: 49;"
      >
        <div class="container-fluid">
          <a class="navbar-brand" href="/#/">MeetManage</a><button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"><span class="navbar-toggler-icon" /></button
          >
          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  href="https://chrome.google.com/webstore/detail/meet-manage/nodegnhodiddibeohajmcamhgblbgblc"
                  >Install</a
                >
              </li>
              <li class="nav-item"><a class="nav-link" href="/#/">Home</a></li>

              {#if $user}
                <li class="nav-item">
                  {#if $user.emailVerified}
                    <a class="nav-link" href="/#/panel">Management Panel</a>
                  {:else}
                    <div class="nav-link">Please check your email for verification</div>
                  {/if}
                </li>
                <li class="nav-item dropdown">
                  <!-- svelte-ignore a11y-invalid-attribute -->
                  <a
                    class="nav-link dropdown-toggle"
                    id="navbarDropdownMenuLink"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">{$user.email}</a
                  >
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li>
                      <a class="dropdown-item" href="/#/account">
                        {$currentRole?.toUpperCase() ?? "FREE"} Account
                      </a>
                    </li>
                    <li>
                      <!-- svelte-ignore a11y-invalid-attribute -->
                      <a href="#" on:click={logout} class="dropdown-item">Sign out</a>
                    </li>
                  </ul>
                </li>{:else}
                <li class="nav-item">
                  <a class="nav-link" href="/#/test">Demo Interface</a>
                </li>
                <li class="nav-item dropdown">
                  <!-- svelte-ignore a11y-invalid-attribute -->
                  <a
                    class="nav-link dropdown-toggle"
                    id="navbarDropdownMenuLink"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">Register/ Log In</a
                  >
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li><a class="dropdown-item" href="/#/login">Log in</a></li>
                    <li><a class="dropdown-item" href="/#/register">Register</a></li>
                  </ul>
                </li>
              {/if}

              <li on:click={toggleDarkMode} class="nav-item">
                <div role="button" class="nav-link">
                  Toggle {inDark ? "Light" : "Dark"} Mode
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/#/EULA">EULA/Privacy</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {#if !wsAvaialable}
        <div class="alert alert-danger m-2" role="alert">
          <h4 class="alert-heading">Websocket services non-operational</h4>
          The websocket service is currently not available. Other functionality is unaffected.
        </div>
      {/if}
      {#if $user?.uid && $user?.emailVerified === false}
        <div class="alert alert-success m-2" role="alert">
          <h4 class="alert-heading">Please verify your email</h4>
          <button
            class="btn btn-primary"
            disabled={emailTimer != null}
            on:click={sendVerifyEmail}>Re-send Email Verification</button
          >
        </div>
      {/if}
      <Router {routes} on:conditionsFailed={conditionsFailed} />
    </div>
  {/if}
</main>
