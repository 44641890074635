<script lang="ts">
  import Panel from "../lib/__panel.svelte";

  import { user } from "../stores/firebase.js";
  import { replace } from "svelte-spa-router";
  import { Database, ref, onValue, push, child } from "firebase/database";
  import type { ClientBackgroundUsers } from "./../serverUtility";
  import {
    TabCommandsList,
    TabCommandData,
    ComputerCommandData,
    Settings,
    ServerTabList,
    isValidColor,
  } from "./../utility";

  export let params = {} as {
    token: string;
  };

  export let db: Database;

  let computerList: ClientBackgroundUsers = {};
  let customAlert: string;

  if (!$user?.uid) {
    replace("/");
  }

  if (params.token.length < 10) {
    alert("Invalid token");
    replace("/panel");
  }

  let readErrors = [];

  const tokenRef = ref(db, "tokens/" + $user.uid + "/" + params.token);

  const computerRef = child(tokenRef, "computers");

  // TODO reduce the scope of this
  onValue(computerRef, (snapshot) => {
    const data: {
      [key: string]: {
        settings: Settings;
        tabList: ServerTabList;
        errors?: { [key: string]: { message: string; tabID: string; timestamp: number } };
        commands?: { [key: string]: any };
      };
    } = snapshot.val();
    if (data) {
      computerList = data;
    }
  });

  onValue(child(tokenRef, "errors"), (snapshot) => {
    const data: {
      [key: string]: {
        message: string;
        tabID: string;
        timestamp: number;
        computer: string;
      };
    } = snapshot.val();
    if (data) {
      Object.entries(data).forEach(([errorKey, errorData]) => {
        if (errorData.timestamp >= Date.now() - 5000) {
          if (readErrors.indexOf(errorKey) == -1) {
            readErrors.push(errorKey);
            errorGen({
              computerName: errorData.computer,
              tabID: errorData.tabID,
              error: errorData.message,
            });
          }
        } else {
          //remove(child(computerRef, "errors/" + errorKey));
        }
      });
    }
  });

  function commandGen(
    computer: string,
    action: "commandTab" | "commandComputer",
    data?: TabCommandData | ComputerCommandData
  ) {
    console.log("commandGen", computer, action, data);
    push(child(tokenRef, "/computers/" + computer + "/commands"), {
      action,
      data,
    }).catch((error) => {
      console.error("Error adding document: ", error, {
        action,
        data,
      });
    });
  }

  function commandTab(
    action: TabCommandsList,
    computerName: string,
    tabID: string,
    args: {
      boolean?: boolean;
      string?: string;
      toggleExcludeUser?: {
        user: string;
        exclude: boolean;
      };
    } = null
  ) {
    commandGen(computerName, "commandTab", {
      tabID: tabID,
      command: action,
      args,
    });
  }

  function closeTab(computerName: string, tabID: string) {
    if (confirm("Are you sure you want to close this tab?")) {
      commandTab("close", computerName, tabID);
    }
  }

  function updateSettings(computer: string) {
    let newSettings: { [key: string]: any } = {};
    document
      .querySelectorAll(`input[data-computer="${computer}"][data-settings-name]`)
      .forEach((element: HTMLInputElement) => {
        let setting = element.getAttribute("data-settings-name");
        if (typeof setting === "string") {
          if (element.getAttribute("type") === "checkbox") {
            if (computerList[computer].settings[setting] !== element.checked) {
              newSettings[setting] = element.checked;
            }
          } else if (setting === "hide_elements_list") {
            let list = element.value.split(",");
            if (
              computerList[computer].settings[setting].sort().toString() !==
              list.sort().toString()
            ) {
              newSettings[setting] = list;
            }
          } else if (computerList[computer].settings[setting] !== element.value) {
            if (element.getAttribute("type") === "number") {
              newSettings[setting] = parseInt(element.value);
            } else {
              if (setting === "background_color") {
                if (!isValidColor(element.value)) {
                  alert("Invalid background color.");
                  element.value = computerList[computer].settings.background_color;
                  return;
                }
              }
              newSettings[setting] = element.value;
            }
          }
        }
      });
    if (newSettings) {
      console.log("updateSettings", computer, newSettings);
      commandGen(computer, "commandComputer", {
        command: "updateSettings",
        args: newSettings,
      });
    }
  }

  function errorGen(data: { computerName: string; tabID: string; error: string }) {
    console.warn(data.computerName, data.tabID, data.error);
    if (data.error === "Window not in focus") {
      (<HTMLInputElement>(
        document.getElementById(`toggleFullScreen${data.computerName + data.tabID}`)
      )).checked = false;
    }
    if (
      document.querySelectorAll(
        `div.accordion-collapse.collapse.show[id="${
          data.computerName + data.tabID
        }collapse"]`
      ).length > 0
    ) {
      let alertDiv = document.createElement("div");
      alertDiv.classList.add(
        "alert",
        "alert-warning",
        "alert-dismissible",
        "fade",
        "show"
      );
      alertDiv.setAttribute("role", "alert");
      alertDiv.innerHTML =
        "<strong>Warning!</strong> " +
        data.error +
        '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
      document
        .querySelector(`div.accordion-body[id="${data.computerName + data.tabID}body"]`)
        .prepend(alertDiv);
      setTimeout(function () {
        // @ts-ignore
        new bootstrap.Alert(alertDiv).close();
      }, 15000);
    }
    console.log(
      "Erorr ignored for hidden tab/computer",
      `div.accordion-collapse.collapse.show[id="${
        data.computerName + data.tabID
      }collapse"]`
    );
  }

  function inputChange(
    event: Event & {
      currentTarget: EventTarget & HTMLInputElement;
    }
  ) {
    const action = event.currentTarget.getAttribute("data-action") as TabCommandsList;
    const computer = event.currentTarget.getAttribute("data-computer") as string;
    const tabID = event.currentTarget.getAttribute("data-tab-id") as string;
    let args: {
      boolean?: boolean;
      string?: string;
      toggleExcludeUser?: {
        user: string;
        exclude: boolean;
      };
    };
    if (computer && tabID && action) {
      switch (action) {
        case "toggleExcludeUser":
          args = {
            toggleExcludeUser: {
              user: event.currentTarget.getAttribute("data-user") as string,
              exclude: event.currentTarget.checked,
            },
          };
          break;
        case "fullVideoUser":
          if (event.currentTarget.checked) {
            args = { string: event.currentTarget.getAttribute("data-user") };
          } else {
            commandGen(computer, "commandTab", {
              tabID,
              command: "regularVideo",
            });
            return;
          }
          break;
        default:
          args = { boolean: event.currentTarget.checked ?? false };
      }
      commandTab(action, computer, tabID, args);
    }
  }

  function inputKeyup(
    event: KeyboardEvent & {
      currentTarget: EventTarget & HTMLInputElement;
    },
    computerName: string
  ) {
    if (event.key === "Enter") {
      let newURL: string = event.currentTarget.value;
      let fullscreen = (<HTMLInputElement>(
        document.getElementById(`toggleFullScreenURL${computerName}`)
      )).checked;
      let regularWindow = (<HTMLInputElement>(
        document.getElementById(`togglePanel${computerName}`)
      )).checked;
      event.currentTarget.value = "";
      commandGen(computerName, "commandComputer", {
        command: "openMeet",
        args: { newMeet: { newURL: newURL, fullscreen, regularWindow } },
      });
    }
  }

  function openNewMeet(
    computerName: string,
    meetCode: string,
    fullscreen: boolean = false,
    regularWindow: boolean = false
  ) {
    commandGen(computerName, "commandComputer", {
      command: "openMeet",
      args: { newMeet: { newURL: meetCode, fullscreen, regularWindow } },
    });
  }
</script>

<svelte:head>
  <title>MeetManage Panel</title>
</svelte:head>

<main>
  {#if customAlert}
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      {customAlert}
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      />
    </div>
  {/if}
  <Panel
    {computerList}
    {openNewMeet}
    {inputChange}
    {commandTab}
    {closeTab}
    {updateSettings}
    {inputKeyup}
  />

  <table class="table table-striped table-responsive" id="masterTable">
    <tbody id="masterTBody" />
  </table>
</main>

<style>
</style>
