<script lang="ts">
  import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    Auth,
    GoogleAuthProvider,
    signInWithPopup,
    signInWithRedirect,
  } from "firebase/auth";
  import { user, privileges } from "../stores/firebase.js";
  import { replace } from "svelte-spa-router";
  import { isMobile } from "is-mobile";
  import EULA from "./EULA.svelte";

  export let auth: Auth;

  $: if ($user?.uid) {
    replace("/");
  }

  const provider = new GoogleAuthProvider();

  let alertMessage: { type: "error" | "success" | "warning"; message: string } = {
    type: "warning",
    message: "",
  };

  function googleSignIn() {
    if (isMobile()) {
      signInWithRedirect(auth, provider);
    } else {
      signInWithPopup(auth, provider)
        .then((result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const user = result.user;
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const email = error.email;
          const credential = GoogleAuthProvider.credentialFromError(error);
          alertMessage = {
            type: "error",
            message: errorMessage,
          };
        });
    }
  }

  function register(event: Event) {
    event.preventDefault();
    const password = document.forms["registerForm"]["password"].value;
    const confirmPassword = document.forms["registerForm"]["confirm_password"].value;
    const email = document.forms["registerForm"]["email"].value;
    if (password != confirmPassword) {
      alertMessage = {
        type: "error",
        message: "Passwords do not match",
      };
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          sendEmailVerification(userCredential.user, {
            url: window.location.origin + "/#/?emailVerified=true",
          });
          replace("/");
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          alertMessage = {
            type: "error",
            message: errorMessage,
          };
        });
    }
  }
</script>

<svelte:head>
  <title>MeetManage Register</title>
</svelte:head>
<main>
  <div class="wrapper container">
    {#if alertMessage.message}
      <div class="alert alert-{alertMessage.type} m-2" role="alert">
        <h4 class="alert-heading">
          {alertMessage.type.charAt(0).toUpperCase() + alertMessage.type.slice(1)}
        </h4>
        <p>{alertMessage.message}</p>
      </div>
    {/if}
    <div class="d-flex justify-content-center"><h2>Sign Up</h2></div>
    <div class="d-flex justify-content-center">
      For an easier experience, where your extension can sync available tokens, use a
      Google account.
    </div>
    <div class="d-flex justify-content-center my-4">
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a class="btn btn-secondary m-auto" href="#" on:click|preventDefault={googleSignIn}>
        Sign up with Google
      </a>
    </div>
    <div class="d-flex justify-content-center">
      <form id="registerForm" on:submit={register}>
        <div class="form-group">
          <label for="email">Email</label><input
            id="email"
            class="form-control"
            type="email"
            name="email"
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label><input
            id="password"
            class="form-control"
            type="password"
            name="password"
            minlength="8"
            title="8 characters minimum"
            value=""
          /><span class="help-block" />
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm Password</label><input
            id="confirmPassword"
            class="form-control"
            type="password"
            name="confirm_password"
            minlength="8"
            value=""
          />
          <span id="confirmPasswordHelp" class="help-block" />
        </div>
        <div class="form-group" id="contact_options" />
        <div class="form-group">
          <div class="d-flex justify-content-center">
            <input class="btn btn-primary" type="submit" value="Submit" /><input
              class="btn btn-default"
              type="reset"
              value="Reset"
            />
          </div>
        </div>
        <span class="help-block" />
        <p>Already have an account? <a href="/#/login">Login here</a>.</p>
      </form>
    </div>
    <div class="d-flex justify-content-center">
      Free accounts are limited to {$privileges?.free?.tokens} tokens and {$privileges
        ?.free?.computers} computers per token.
    </div>
    <EULA />
  </div>
</main>
