export class DefaultState {
    constructor() {
        this.grid = false;
        this.videoOnlyGrid = false;
        this.pinned = false;
        this.elements = true;
        this.elementsAutoHidden = false;
        this.fullscreen = false;
        this.pointerLock = false;
        this.inCall = false;
        this.meetURL = "";
        this.curtain = false;
        this.excluded = [];
        this.chat = [];
        this.neverMute = false;
        this.videoDivClass = ".p2hjYe.TPpRNe";
        this.secondaryVideoDivClass = ".";
        this.cameraEnabled = false;
        this.micEnabled = false;
        this.uniqueUsersList = [];
        this.web_sync_url = "";
    }
}
export const defaultElementList = [
    ".pZFrDd.lMHHZ",
    ".ZY8hPc.gtgjre.pZFrDd",
    ".Jrb8ue",
    ".aGJE1b",
    ".misTTe.Mp2Z0b.Mh0NNb",
    ".ncMv2e.MvD9Jd",
    ".cM3h5d.NSvDmb",
    ".XkylE.N1zGnc",
    "div.rG0ybd.LCXT6",
    "div.rKOYsc",
    "div.aGWPv",
    "div.HNwH4d",
    "div.atLQQ",
    "div.rG0ybd.xPh1xb.P9KVBf",
    "div.g3VIld.iUQSvf.vDc8Ic.J9Nfi.iWO5td",
    "div.c8mVDd",
    ".TZFSLb.zfYLqc.nfvcwb.qjTEB",
];
export class DefaultSettings {
    constructor() {
        this.background_color = "#111";
        this.background_elements = "div.crqnQb";
        this.auto_fullscreen = false;
        this.auto_fullscreen_video = false;
        this.auto_hide_elements = false;
        this.bottom_bar_selector = "div.UnvNgf.Sdwpn.P9KVBf";
        this.accept_eula = false;
        this.socketio_url = "";
        this.auto_click_join = false;
        this.curtain_url = "";
        this.computer_name = "";
        this.socketio_token = "";
        this.hide_elements_list = defaultElementList;
        this.hide_elements_on_full = true;
        this.auto_curtain_enabled = false;
        this.auto_curtain_amount = 1;
        this.notify_updates = true;
        this.web_sync_url = "https://gallimo.com/mmsync";
        this.talking_indicator = "div.atLQQ.kssMZb";
        this.talking_indicator_active = "div.IisKdb.u5mc1b.BbJhmb.YE1TS.YFyDbd";
        this.mute_observer = "div.JHK7jb.Nep7Ue";
        this.mute_icon = "div.JHK7jb.Nep7Ue.FTMc0c";
        this.firebase_token = "";
    }
}
export function isValidColor(strColor) {
    if (!strColor || typeof strColor !== "string") {
        return false;
    }
    const s = new Option().style;
    s.color = strColor;
    // return 'false' if color wasn't assigned
    const RegExp = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;
    return s.color === strColor.toLowerCase() || RegExp.test(strColor);
}
export async function fetchData(url) {
    let returnData = null;
    try {
        const response = await fetch(url);
        returnData = await response.json();
    }
    catch (e) {
        console.error(e);
    }
    return returnData;
}
export async function syncWeb(url, oldSettings) {
    let newData = await fetchData(url);
    let newDefaultSettings = new DefaultSettings();
    let updates = {};
    if (newData) {
        for (const [key, value] of Object.entries(newData)) {
            if (newDefaultSettings[key] &&
                typeof newDefaultSettings[key] === typeof newData[key]) {
                let changed = false;
                if (oldSettings[key] == null) {
                    changed = true;
                }
                else if (Array.isArray(oldSettings[key])) {
                    if (JSON.stringify(oldSettings[key].sort()) !== JSON.stringify(value.sort())) {
                        changed = true;
                    }
                }
                else if (typeof oldSettings[key] === "object") {
                    if (JSON.stringify(oldSettings[key]) !== JSON.stringify(value)) {
                        changed = true;
                    }
                }
                else if (oldSettings[key] !== value) {
                    changed = true;
                }
                if (changed) {
                    console.log(`${key} changed from ${oldSettings[key]} to ${value}`);
                    updates[key] = value;
                }
            }
            else {
                console.log(`${key} is not a valid setting. Right type:`, typeof newDefaultSettings[key] === typeof newData[key]);
            }
        }
    }
    if (Object.keys(updates).length > 0) {
        return updates;
    }
    else {
        console.log("No settings updates");
        return false;
    }
}
