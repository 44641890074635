<script lang="ts">
  import { user, currentRole, privileges } from "../stores/firebase.js";
  import { replace } from "svelte-spa-router";
</script>

<div class="container py-3">
  <h2>Subscribe to MeetManage</h2>
  <p>
    This is a MeetManage testing server. Your subscription will help continue fund
    development of both the extension and the server.
  </p>
  <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
    <div class="col">
      <div class="card mb-4 rounded-3 shadow-sm">
        <div class="card-header py-3"><h4 class="my-0 fw-normal">Free</h4></div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">$0</h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>Limited to 2 tokens</li>
            <li>Limited to 2 computers per token</li>
          </ul>
          {#if !$user?.uid}
            <a class="w-100 btn btn-lg btn-outline-primary" href="/#/register"
              >Sign up for free</a
            >
          {:else}
            <div class="w-100 btn btn-lg btn-primary disabled">Signed up</div>
          {/if}
        </div>
      </div>
    </div>
    {#each Object.entries($privileges) as [plan, { computers, tokens, price }]}
      {#if price !== 0}
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">{plan.toUpperCase()}</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">${price}</h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>Limited to {tokens} tokens</li>
                <li>Limited to {computers} computers per token</li>
              </ul>
              {#if !$user?.uid}
                <a class="w-100 btn btn-lg btn-primary" href="/#/register"
                  >Sign up first</a
                >
              {:else if $currentRole !== plan}
                <a class="w-100 btn btn-lg btn-primary" href="/#/register">Subscribe</a>
              {:else}
                <a class="w-100 btn btn-lg btn-primary" href="/#/register">Unsubscribe</a>
              {/if}
            </div>
          </div>
        </div>
      {/if}
    {/each}
  </div>
  <h3>Terms of Service</h3>
  <p>
    Prices and restrictions are subject to change. Usage must be in accordance with
    federal or state law, whichever takes precedence. Abuse of this service is not
    tolerated and your account may be terminated at any time if you are believed to be
    doing so.
  </p>
  <h3>Privacy</h3>
  <p>
    Your data is not sold to any third parties. Data may be shared with Stripe in order to
    process payments. Google Analytics is used to track usage and usage patterns.
  </p>
</div>
